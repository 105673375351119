var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-builder"},[_c('div',{staticClass:"question-builder__content"},[_c('div',{staticClass:"mb-15"},[_c('label',{attrs:{"for":""},domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalLabel1)}}),_c('a-textarea',{attrs:{"auto-size":{ minRows: 2 }},model:{value:(_vm.questionData.questionText),callback:function ($$v) {_vm.$set(_vm.questionData, "questionText", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"questionData.questionText"}})],1),_c('div',{staticClass:"question-builder__options"},[_c('label',{attrs:{"for":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalPlaceholder1)}}),_c('span',[_vm._v(_vm._s(_vm.questionData.options.length)+"/10")])]),_c('div',{staticClass:"question-builder__options-list",class:{
          'ant-input-group-addon-small': _vm.questionData.options.length < 3,
        }},[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"tag":"ul","handle":".ant-btn--drag"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.questionData.options),callback:function ($$v) {_vm.$set(_vm.questionData, "options", $$v)},expression:"questionData.options"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.questionData.options),function(option,index){return _c('a-input',{key:index,staticClass:"options-input",attrs:{"placeholder":"Add option text"},on:{"pressEnter":function($event){return _vm.addOption(index)}},model:{value:(option.optionText),callback:function ($$v) {_vm.$set(option, "optionText", $$v)},expression:"option.optionText"}},[(
                  _vm.questionData.options.length > 2 &&
                  index !== _vm.questionData.options.length - 1 &&
                  !_vm.editMode
                )?_c('a-button',{staticClass:"ant-btn--drag",attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_c('font-awesome-icon',{attrs:{"icon":"grip-vertical"}})],1):_vm._e(),(
                  option.optionText === '' ||
                  (_vm.questionData.options[index + 1] === undefined &&
                    index + 1 < _vm.maxOptions)
                )?_c('a-button',{attrs:{"slot":"addonAfter"},domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalButton3)},on:{"click":function($event){return _vm.addOption(index)}},slot:"addonAfter"}):_c('a-button',{staticClass:"ant-btn--delete",attrs:{"slot":"addonAfter"},domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalButton4)},on:{"click":function($event){return _vm.removeOption(index)}},slot:"addonAfter"})],1)}),1)],1)],1)])]),_c('div',{staticClass:"question-builder__cta"},[_c('span',{staticClass:"question-builder__cta-info"},[_vm._v(_vm._s(_vm.successInfoMessage))]),_c('div',[_c('a-tooltip',{attrs:{"placement":"top","overlayClassName":"ant-tooltip--small","visible":_vm.cancelTooltip}},[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalCancelTooltip)}})]),_c('a-button',{domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalButton1)},on:{"mouseenter":_vm.setCancelTooltip,"mouseleave":function($event){_vm.cancelTooltip = false},"click":function($event){return _vm.$emit('question-builde:close')}}})],2),_c('a-button',{attrs:{"type":"primary","disabled":_vm.disableAddBtn},domProps:{"innerHTML":_vm._s(_vm.langObj['s4-customQuestions'].modalButton2)},on:{"click":_vm.saveData}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }