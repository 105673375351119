<template>
  <div class="videoAds">
    <a-breadcrumb class="breadcrum">
      <a-breadcrumb-item
        ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item><a href="/products">Products</a></a-breadcrumb-item>
      <a-breadcrumb-item>{{ $route.meta.surveyTitle }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="authoring">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="
          surveyTypeID === 102
            ? 'Reticle Alignment Test'
            : $route.name !== 'pack-test-key-metrics'
            ? 'video'
            : 'pack'
        "
      />
      <section class="stepAuthor">
        <div class="questions">
          <!-- brands and cogni -->
          <div class="standard">
            <!-- brand section -->
            <div class="container">
              <div class="quizTitle">
                <span v-html="langObj['s4-brand'].quiz"> </span>
                <a-tooltip placement="topRight">
                  <template slot="title">
                    <span v-html="langObj['s4-brand'].tip"></span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </div>
            </div>
            <!-- brief -->
            <p class="brief" v-html="langObj['s4-brand'].brief"></p>
            <!-- brand input -->
            <div class="brands">
              <a-input
                v-for="(brand, index) in brands"
                :placeholder="langObj['s4-brand'].placeholder"
                class="brandInput"
                :key="index"
                v-model="brand.name"
                @pressEnter="addNewBrand(index)"
                @paste="pasteBrand(index, $event)"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="plus-circle"
                  v-show="!brand.filled"
                  @click="addNewBrand(index)"
                />
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="minus-circle"
                  v-show="brand.filled"
                  @click="onDeleteBrand(index)"
                />
              </a-input>
            </div>
          </div>

          <!-- cogni section -->
          <div class="container">
            <div class="quizTitle">
              <span v-html="langObj['s4-message'].quiz"> </span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span v-html="langObj['s4-message'].tip"></span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <!-- brief -->
            <p class="brief" v-html="langObj['s4-message'].brief"></p>
            <!-- cogni input -->
            <a-input
              v-for="(cogni, index) in cognis"
              :placeholder="langObj['s4-message'].placeholder"
              class="cogniInput"
              :key="index"
              v-model="cogni.name"
              @paste="pasteCogni(index, $event)"
              @pressEnter="addNewCogni(index)"
            >
              <a-icon
                slot="addonAfter"
                theme="filled"
                type="plus-circle"
                v-show="!cogni.filled"
                @click="addNewCogni(index)"
              />
              <a-icon
                slot="addonAfter"
                theme="filled"
                type="minus-circle"
                v-show="cogni.filled"
                @click="onDeleteCogni(index)"
              />
            </a-input>
          </div>

          <a-divider>Custom Questions</a-divider>
          <!-- custom question -->
          <div class="custom">
            <p class="brief" v-html="langObj['s4-cQues'].quiz"></p>
            <a-button class="custAntBtn" @click="addCustomQuestionModal = true">
              Add custom question
            </a-button>
          </div>

          <QuestionsListPreview
            v-if="
              customQuestionsList !== null &&
              customQuestionsList.CustomQuestions.length
            "
            :questionsList="customQuestionsList.CustomQuestions"
            :langObj="langObj"
            :questionSettings="true"
            :loading="questionsConfig.loadingNewQuestions"
            @deleteCustomQuestions="deleteCustomQuestions"
            @editQuestion="editQuestion"
          ></QuestionsListPreview>
        </div>
        <CompletionProgress :checkList="quizs"></CompletionProgress>
      </section>
      <StepNaviBar
        :stepIndex="stepIndex"
        :nextDisable="!brandStatus || !cogniStatus"
        @nextHandler="nextStep"
        :prevDisable="false"
        @prevHandler="prevStep"
        prevBtnTooltip="Back to Brand Strategy"
        nextBtnTooltip="Go to Key Metrics"
        :stepsType="
          surveyTypeID === 102
            ? 'Reticle Alignment Test'
            : $route.name !== 'pack-test-key-metrics'
            ? 'video'
            : 'pack'
        "
      />
    </div>

    <a-modal
      :title="questionsModalTitle"
      :visible="addCustomQuestionModal"
      :width="questionsConfig.editMode ? 650 : 800"
      okText="Save"
      :okButtonProps="{
        props: { disabled: !questionsData.CustomQuestions.length },
      }"
      :footer="null"
      @cancel="closeQuestionBuilder"
      :destroyOnClose="true"
      wrapClassName="ant-modal--custom-questions"
    >
      <div class="choose-questions-nav" v-if="!questionsConfig.editMode">
        <a-button
          @click="
            (questionsConfig.activeMode = 'builder'),
              (questionsConfig.activeBuilder = questionsConfig.type)
          "
          :class="{
            'ant-btn--active': questionsConfig.activeMode === 'builder',
          }"
          v-html="langObj['s4-customQuestions'].modalTab1"
        ></a-button>
        <a-button
          @click="
            (questionsConfig.activeMode = 'examples'),
              (questionsConfig.activeBuilder = null)
          "
          :class="{
            'ant-btn--active': questionsConfig.activeMode === 'examples',
          }"
          v-html="langObj['s4-customQuestions'].modalTab2"
        ></a-button>
      </div>
      <div class="choose-questions">
        <div class="choose-questions__types" v-if="!questionsConfig.editMode">
          <a-radio-group
            v-model="questionsConfig.type"
            disable
            :class="{
              'ant-radio-group--border-b': questionsData.CustomQuestions.length,
              'ant-radio-group--disabled':
                questionsConfig.previewQuestionsList ||
                questionsConfig.editMode,
            }"
          >
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              selected
              value="text"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType1"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              selected
              value="essay"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType6"
              ></span>
            </a-radio>
            <a-radio :style="questionsConfig.radioBtnTypesStyle" value="radio">
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType2"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="checkbox"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType3"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="rank-dragdrop"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType4"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="table-radio"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType7"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="table-checkbox"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType5"
              ></span>
            </a-radio>
          </a-radio-group>
        </div>
        <div class="choose-questions__preview">
          <template>
            <QuestionsTypePreview
              v-if="questionsConfig.type === 'text'"
              :title="langObj['s4-customQuestions'].modalQuestionType1"
              :subTitle="langObj['s4-customQuestions'].modalTextQIntro"
              :exampleQuestionText="
                langObj['s4-customQuestions'].modalTextQExampleLabel
              "
              :description="
                langObj['s4-customQuestions'].modalTextQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'text' &&
                questionsConfig.activeBuilder === 'text'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <TextTypeBuilder
                v-if="
                  questionsConfig.type === 'text' &&
                  questionsConfig.activeBuilder === 'text'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
              ></TextTypeBuilder>
              <TextTypeExample
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalTextQExampleLabel
                "
                :langObj="langObj"
                :questionSettings="false"
                v-else
              ></TextTypeExample>
            </QuestionsTypePreview>
            <QuestionsTypePreview
              v-if="questionsConfig.type === 'essay'"
              :title="langObj['s4-customQuestions'].modalQuestionType6"
              :subTitle="langObj['s4-customQuestions'].modalEssayQIntro"
              :exampleQuestionText="
                langObj['s4-customQuestions'].modalEssayQExampleLabel
              "
              :description="
                langObj['s4-customQuestions'].modalEssayQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'essay' &&
                questionsConfig.activeBuilder === 'essay'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <TextTypeBuilder
                v-if="
                  questionsConfig.type === 'essay' &&
                  questionsConfig.activeBuilder === 'essay'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
                type="essay"
              ></TextTypeBuilder>
              <TextTypeExample
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalEssayQExampleLabel
                "
                :langObj="langObj"
                :questionSettings="false"
                type="essay"
                v-else
              ></TextTypeExample>
            </QuestionsTypePreview>
            <QuestionsTypePreview
              v-if="questionsConfig.type === 'radio'"
              :title="langObj['s4-customQuestions'].modalQuestionType2"
              :subTitle="langObj['s4-customQuestions'].modalSingleQIntro"
              :description="
                langObj['s4-customQuestions'].modalSingleQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'radio' &&
                questionsConfig.activeBuilder === 'radio'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <MultiOptionsTypeBuilder
                v-if="
                  questionsConfig.type === 'radio' &&
                  questionsConfig.activeBuilder === 'radio'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                type="radio"
                sort="NONE"
                :langObj="langObj"
              ></MultiOptionsTypeBuilder>
              <SingleChoiceExample
                v-else
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalSingleQExampleLabel
                "
              ></SingleChoiceExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'checkbox'"
              :title="langObj['s4-customQuestions'].modalQuestionType3"
              :subTitle="langObj['s4-customQuestions'].modalMultiQIntro"
              :description="
                langObj['s4-customQuestions'].modalMultiQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'checkbox' &&
                questionsConfig.activeBuilder === 'checkbox'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <MultiOptionsTypeBuilder
                v-if="
                  questionsConfig.type === 'checkbox' &&
                  questionsConfig.activeBuilder === 'checkbox'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                type="checkbox"
                sort="SHUFFLE"
                :langObj="langObj"
              ></MultiOptionsTypeBuilder>
              <MultiChoiceExample
                v-else
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalMultiQExampleLabel
                "
              ></MultiChoiceExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'rank-dragdrop'"
              :title="langObj['s4-customQuestions'].modalQuestionType4"
              :subTitle="langObj['s4-customQuestions'].modalRankQIntro"
              :description="
                langObj['s4-customQuestions'].modalRankQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'rank-dragdrop' &&
                questionsConfig.activeBuilder === 'rank-dragdrop'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <MultiOptionsTypeBuilder
                v-if="
                  questionsConfig.type === 'rank-dragdrop' &&
                  questionsConfig.activeBuilder === 'rank-dragdrop'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                type="rank-dragdrop"
                sort="SHUFFLE"
                :langObj="langObj"
              ></MultiOptionsTypeBuilder>
              <RankExample
                v-else
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalRankQExampleLabel
                "
              ></RankExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'table-radio'"
              :title="langObj['s4-customQuestions'].modalQuestionType7"
              :subTitle="langObj['s4-customQuestions'].modalGridQIntro"
              :description="
                langObj['s4-customQuestions'].modalGridQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'table-radio' &&
                questionsConfig.activeBuilder === 'table-radio'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <GridTypeBuilder
                v-if="
                  questionsConfig.type === 'table-radio' &&
                  questionsConfig.activeBuilder === 'table-radio'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
              ></GridTypeBuilder>
              <GridExample
                v-else
                :langObj="langObj"
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalGridQExampleLabel
                "
                type="table-radio"
              ></GridExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'table-checkbox'"
              :title="langObj['s4-customQuestions'].modalQuestionType5"
              :subTitle="langObj['s4-customQuestions'].modalGridCheckboxQIntro"
              :description="
                langObj['s4-customQuestions'].modalGridCheckboxQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'table-checkbox' &&
                questionsConfig.activeBuilder === 'table-checkbox'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <GridTypeBuilder
                v-if="
                  questionsConfig.type === 'table-checkbox' &&
                  questionsConfig.activeBuilder === 'table-checkbox'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
                type="table-checkbox"
              ></GridTypeBuilder>
              <GridExample
                v-else
                :langObj="langObj"
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalGridCheckboxQExampleLabel
                "
              ></GridExample>
            </QuestionsTypePreview>

            <div
              class="flex justify-center"
              v-if="questionsConfig.activeBuilder === null"
            >
              <a-button
                type="primary"
                @click="setBuilderType"
                v-html="langObj['s4-customQuestions'].modalButton5"
              >
              </a-button>
            </div>
          </template>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar.vue";
import QuestionsTypePreview from "@/components/questions/QuestionsTypePreview.vue";
import TextTypeExample from "@/components/questions/TextTypeExample.vue";
import TextTypeBuilder from "@/components/questions/TextTypeBuilder.vue";
import SingleChoiceExample from "@/components/questions/SingleChoiceExample.vue";
import MultiChoiceExample from "@/components/questions/MultiChoiceExample.vue";
import RankExample from "@/components/questions/RankExample.vue";
import GridExample from "@/components/questions/GridExample.vue";
import QuestionsListPreview from "@/components/questions/QuestionsListPreview.vue";
import MultiOptionsTypeBuilder from "@/components/questions/MultiOptionsTypeBuilder.vue";
import GridTypeBuilder from "@/components/questions/GridTypeBuilder.vue";
import { mutations } from "@/utils/store.js";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import CompletionProgress from "@/components/general/CompletionProgress.vue";

export default {
  name: "KeyMetrics",
  components: {
    ProgressBar,
    StepNaviBar,
    QuestionsTypePreview,
    TextTypeExample,
    SingleChoiceExample,
    MultiChoiceExample,
    RankExample,
    GridExample,
    TextTypeBuilder,
    QuestionsListPreview,
    MultiOptionsTypeBuilder,
    GridTypeBuilder,
    CompletionProgress,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      minBrand: 3,
      maxBrand: 7,
      minCogni: 3,
      maxCogni: 5,
      brands: [{ name: "", filled: false }],
      cognis: [{ name: "", filled: false }],
      stepIndex: 4,
      surveyID: 0,
      langObj: {},
      client: {},
      quizs: [],
      addCustomQuestionModal: false,
      questionsConfig: {
        type: "text",
        radioBtnTypesStyle: {
          display: "block",
          height: "30px",
          lineHeight: "30px",
        },
        activeBuilder: "text",
        previewQuestionsList: false,
        editModeItemIndex: null,
        editMode: false,
        activeMode: "builder",
        loadingNewQuestions: false,
      },
      questionsData: {
        surveyID: null,
        CustomQuestions: [],
      },
      customQuestionsList: null,
      editableSurvey: null,
      breadcrumbTitle: "",
      user: null,
      surveyTypeID: null,
    };
  },
  computed: {
    brandStatus() {
      if (this.brands.filter((brand) => brand.filled).length >= this.minBrand) {
        return true;
      } else {
        return false;
      }
    },
    cogniStatus() {
      if (this.cognis.filter((cogni) => cogni.filled).length >= this.minCogni) {
        return true;
      } else {
        return false;
      }
    },
    questionsModalTitle() {
      let title = "Examples";
      if (this.questionsConfig.previewQuestionsList) {
        title = "My questions list";
      } else if (
        this.questionsConfig.activeBuilder !== null &&
        !this.questionsConfig.editMode
      ) {
        title = "Create a question";
      } else if (
        this.questionsConfig.activeBuilder !== null &&
        this.questionsConfig.editMode
      ) {
        title = "Edit a question";
      }
      return title;
    },
  },
  watch: {
    brandStatus() {
      this.updateQuiz();
    },
    cogniStatus() {
      this.updateQuiz();
    },
    "questionsConfig.type": {
      handler: function () {
        if (!this.questionsConfig.editMode) {
          this.questionsConfig.activeBuilder = null;
        }
        if (this.questionsConfig.activeMode === "builder") {
          this.questionsConfig.activeBuilder = this.questionsConfig.type;
        }
      },
      deep: true,
    },
  },
  methods: {
    onDeleteBrand(index) {
      if (this.brands.length === 1) {
        this.brands[0] = { name: "", filled: false };
      } else {
        this.brands.splice(index, 1);
      }
    },
    addNewBrand(index) {
      if (this.brands[index].name !== "") {
        // there is content there
        this.brands[index].filled = true;
        if (this.brands.length < this.maxBrand) {
          this.brands.push({ name: "", filled: false });
          setTimeout(() => {
            const brandInputs = document.querySelectorAll(".brandInput input");
            brandInputs[this.brands.length - 1].focus();
          }, 200);
        }
      }
    },
    pasteBrand(index, e) {
      let clipboardData, pastedData;
      e.stopPropagation();
      e.preventDefault();

      clipboardData = e.clipboardData || window.clipboardData;

      pastedData = clipboardData.getData("Text");
      pastedData = pastedData.split("\n");

      pastedData = pastedData.filter((con) => {
        return con !== "" && con !== "\r";
      });

      pastedData.forEach((con, i) => {
        this.brands[index + i].name = con;
        this.brands[index + i].filled = true;

        if (this.brands.length < this.maxBrand) {
          this.brands.push({ name: "", filled: false });
        }
      });
    },
    onDeleteCogni(index) {
      if (this.cognis.length === 1) {
        this.cognis[0] = { name: "", filled: false };
      } else {
        this.cognis.splice(index, 1);
      }
    },
    pasteCogni(index, e) {
      let clipboardData, pastedData;
      e.stopPropagation();
      e.preventDefault();

      clipboardData = e.clipboardData || window.clipboardData;

      pastedData = clipboardData.getData("Text");
      pastedData = pastedData.split("\n");
      pastedData = pastedData.filter((con) => {
        return con !== "" && con !== "\r";
      });

      pastedData.forEach((con, i) => {
        this.cognis[index + i].name = con;
        this.cognis[index + i].filled = true;

        if (this.cognis.length < this.maxCogni) {
          this.cognis.push({ name: "", filled: false });
        }
      });
    },
    addNewCogni(index) {
      if (this.cognis[index].name !== "") {
        this.cognis[index].filled = true;
        if (this.cognis.length < this.maxCogni) {
          this.cognis.push({ name: "", filled: false });
          setTimeout(() => {
            const cogniInputs = document.querySelectorAll(".cogniInput input");
            cogniInputs[this.cognis.length - 1].focus();
          }, 200);
        }
      }
    },
    prevStep() {
      this.$router.push({
        name: this.$route.meta.keyMetricsPrevRoute || "vid-brand-strategy",
        params: { id: this.surveyID },
      });
    },
    nextStep() {
      // call ws to save questions
      let brandOptions = this.brands
        .filter((brand) => brand.name !== "")
        .map((brand) => {
          return { optionText: brand.name };
        });
      let cogniOptions = this.cognis
        .filter((cogni) => cogni.name !== "")
        .map((cogni) => {
          return {
            optionText: cogni.name,
          };
        });

      let questionObj = {
        surveyID: this.surveyID,
        questions: [
          {
            SurveyQuestionType: 4, //brand
            QuestionOptions: brandOptions,
          },
          {
            SurveyQuestionType: 6, //cogni
            QuestionOptions: cogniOptions,
          },
        ],
        userId: this.user.EncrypteduserID,
      };
      this.$emit("cogniCompleteEvent", brandOptions, cogniOptions);

      const questionsPromise = new Promise((resolve) => {
        wsUtils.SaveStandardSurveyQuestions(
          questionObj,
          resolve
        );
      });

      questionsPromise.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        this.$router.push({
          name: this.$route.meta.keyMetricsNextRoute || "vid-audience",
          params: { id: this.surveyID },
        });
      });

      let surveySummary = {
        customQuestions: this.customQuestionsList.CustomQuestions.map(
          (item) => {
            return item.questionText;
          }
        ),
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });
    },
    updateQuiz() {
      this.quizs = [
        {
          quizName: this.langObj["s4-brand"].robot,
          completed: this.brandStatus,
        },
        {
          quizName: this.langObj["s4-message"].robot,
          completed: this.cogniStatus,
        },
      ];
    },
    setBuilderType() {
      this.questionsConfig.activeBuilder = this.questionsConfig.type;
      this.questionsConfig.activeMode = "builder";
    },
    setQuestionData(data, index) {
      let questions;
      if (index !== null && index !== undefined) {
        this.customQuestionsList.CustomQuestions[index] = data;
        this.questionsConfig.editModeItemIndex = null;
        this.questionsConfig.editMode = false;
        questions = this.customQuestionsList;
      } else {
        this.questionsData.CustomQuestions.push(data);
        questions = this.questionsData;
      }
      this.questionsConfig.loadingNewQuestions = true;
      this.insertCustomQuestions(questions);
    },
    deleteCustomQuestions(index) {
      this.customQuestionsList.CustomQuestions.splice(index, 1);
      this.insertCustomQuestions(this.customQuestionsList);
    },
    insertCustomQuestions(data) {
      data.userId = this.user.EncrypteduserID;
      const insertCustomQuestionPromise = new Promise((resolve) => {
        wsUtils.InsertCustomQuestion(data, resolve);
      });

      this.addCustomQuestionModal = false;
      this.questionsData.CustomQuestions = [];
      this.questionsConfig.activeBuilder = "text";
      this.questionsConfig.type = "text";

      insertCustomQuestionPromise.then(() => {
        this.questionsConfig.loadingNewQuestions = false;
        this.getCustomQuestions();
      });
    },
    editQuestion(data) {
      this.addCustomQuestionModal = true;
      this.questionsConfig.editMode = true;
      this.questionsConfig.editModeItemIndex = data.index;
      this.questionsConfig.previewQuestionsList = false;
      this.questionsConfig.type = data.questionType;
      this.questionsConfig.activeBuilder = data.questionType;
    },
    closeQuestionBuilder() {
      this.addCustomQuestionModal = false;
      let _this = this;
      this.getCustomQuestions();

      setTimeout(function () {
        _this.questionsConfig.editMode = false;
        _this.questionsConfig.activeBuilder = "text";
        _this.questionsConfig.type = "text";
      }, 500);
    },
    getCustomQuestions() {
      const getCustomQuestionsPromise = new Promise((resolve) => {
        wsUtils.GetCustomQuestion(this.surveyID, resolve);
      });
      getCustomQuestionsPromise.then((data) => {
        this.customQuestionsList = data;
        let questionsType = data.CustomQuestions.map((item) => {
          return { QuestionType: item.questionType };
        });
        mutations.setSurveyPriceQuestion(questionsType);

        let surveyPriceSetData = {
          Question: questionsType,
        };
        let currentSurveyPriceData = jsUtils.getSurveyPrice();
        jsUtils.setSurveyPrice({
          ...currentSurveyPriceData,
          ...surveyPriceSetData,
        });
        this.getSurveyTotalPrice();
      });
    },
    closeCustomQuestionsModal() {
      this.addCustomQuestionModal = false;
      this.questionsConfig.type = "text";
      this.questionsConfig.activeBuilder = null;
      this.questionsConfig.previewQuestionsList = false;
      this.questionsConfig.editModeItemIndex = null;
      this.questionsConfig.editMode = false;
    },
    prefillStandardQuestions() {
      this.editableSurvey = jsUtils.getEditableSurvey();

      if (
        this.editableSurvey &&
        this.editableSurvey.QuestionDetails.length > 0
      ) {
        const brands = this.editableSurvey.QuestionDetails.filter(
          (item) => item.SurveyQuestionTypeID === 4
        );

        const brandsQuestions = brands[0].QuestionOptions.map((item) => {
          const brandItem = {
            filled: true,
            name: item.OptionText,
          };
          return brandItem;
        });
        this.brands.unshift(...brandsQuestions);

        const key = this.editableSurvey.QuestionDetails.filter(
          (item) => item.SurveyQuestionTypeID === 6
        );
        const keyMessages = key[0].QuestionOptions.map((item) => {
          const keyItem = {
            filled: true,
            name: item.OptionText,
          };
          return keyItem;
        });
        this.cognis.unshift(...keyMessages);
      }
    },
  },
  activated() {
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.surveyID = this.$route.params.id;
    this.questionsData.surveyID = this.surveyID;
    this.updateQuiz();

    this.customQuestionsList = null;
    this.getCustomQuestions();
    this.brands = [{ name: "", filled: false }];
    this.cognis = [{ name: "", filled: false }];
    this.prefillStandardQuestions();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.breadcrumbTitle =
      this.$route.name !== "pack-test-key-metrics" ? "Video Ad" : "Pack";
  },
};
</script>

<style lang="scss" scoped>
section.stepAuthor {
  .questions {
    padding: 2em;
    width: 60vw;
    border: 1px solid var(--lineGrey);
    border-bottom: none;
    height: 100%;
    overflow: hidden;
    overflow: auto;
    align-content: start;
    row-gap: 2em;

    .container {
      margin-bottom: 2em;
    }

    .brands {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }
  }
}

.custom {
  display: flex;
  align-items: start;
  column-gap: 2em;
}

.ant-input-group-wrapper {
  margin-bottom: 0.8em;
}

.quizTitle {
  display: flex;
  align-items: center;

  .completeIcon {
    position: relative;
    left: auto;
  }
}

.btns {
  button {
    margin-left: 10px;
  }
}

h3 {
  color: #1890ff;
  margin-bottom: 2em;
}

.choose-questions-nav {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;

  .ant-btn {
    min-width: 100px;
    border-bottom: 0;
    margin-right: -1px;
    border-radius: 0;
    &:hover {
      border-color: #d9d9d9;
    }
    &--active {
      background: var(--hsblue);
      color: #fff;
      border-color: var(--hsblue) !important;
    }
  }
}

.choose-questions {
  display: flex;
  width: 100%;
  &__types {
    border-right: 1px solid #e8e8e8;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    min-width: 168px;
    margin-right: 24px;
    .ant-radio-group {
      margin-bottom: 16px;
      padding-bottom: 8px;
      &--border-b {
        border-bottom: 1px solid #e8e8e8;
      }
      &--disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
  &__preview {
    flex: 1;
  }
}
</style>

<style lang="scss">
.ant-input-group-addon {
  background-color: #787878;
  transition: background-color 0.3s;

  .anticon {
    color: white;
    font-size: 1.2em;
  }

  &:hover {
    background-color: #555;
  }
}

.ant-modal {
  &--custom-questions {
    backdrop-filter: none !important;
    .ant-modal-body {
      display: block;
      max-height: inherit;
    }
  }
}
</style>
