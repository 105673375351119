<template>
  <div class="questions-list">
    <div
      v-for="(item, index) in getQuestionsType"
      :key="index"
      class="questions-list__item"
    >
      <component
        :is="`Type${item.itemType}`"
        :exampleQuestionText="item.questionText"
        :options="item.options"
        :subQuestions="item.subQuestions"
        :langObj="langObj"
        :index="index"
        :questionType="item.questionType"
        @editQuestion="editQuestion"
        @deleteTempQuestion="$emit('deleteCustomQuestions', index)"
        :questionSettings="questionSettings"
        :type="item.questionType"
      ></component>
    </div>
    <p class="questions-list__loading" v-if="loading">
      <i></i>Loading recently added questions...
    </p>
  </div>
</template>

<script>
import QuestionsListPreviewItem from "@/components/questions/QuestionsListPreviewItem.vue";
import Typeradio from "@/components/questions/SingleChoiceExample.vue";
import Typetext from "@/components/questions/TextTypeExample.vue";
import Typecheckbox from "@/components/questions/MultiChoiceExample.vue";
import Typerankdragdrop from "@/components/questions/RankExample.vue";
import Typetableradio from "@/components/questions/GridExample.vue";
import Typeessay from "@/components/questions/TextTypeExample.vue";
import Typetablecheckbox from "@/components/questions/GridExample.vue";

export default {
  name: "QuestionsListPreview",
  props: {
    questionsList: {
      type: Array,
    },
    langObj: {
      type: Object,
    },
    questionSettings: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    QuestionsListPreviewItem,
    Typeradio,
    Typetext,
    Typecheckbox,
    Typerankdragdrop,
    Typetableradio,
    Typeessay,
    Typetablecheckbox,
  },
  data() {
    return {
      deleteInfo: [],
    };
  },

  methods: {
    editQuestion(data) {
      this.$emit("editQuestion", data);
    },
  },
  computed: {
    getQuestionsType() {
      const questionType = this.questionsList.map((item) => {
        return {
          ...item,
          itemType: item.questionType.replaceAll("-", ""),
        };
      });
      return questionType;
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-list {
  padding-right: 70px;
  &__item {
    max-width: 550px;
    margin-bottom: 20px;
    border: 1px solid #efefef;
    position: relative;
    padding: 20px;
    border-radius: 3px;
  }
  &__loading {
    font-size: 12px;
    display: flex;
    align-items: center;
    i {
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 3px solid var(--hsblueDark);
      width: 20px;
      height: 20px;
      animation: spin 0.9s linear infinite;
      display: block;
      margin-right: 5px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.questions-list-preview {
  padding-left: 15px;
  &--empty {
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 20px;
      margin: 0 5px 1px 0;
    }
  }
  li {
    margin-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 15px;
    position: relative;
    font-size: 14px;
    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
