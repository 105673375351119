<template>
  <div class="questions-type-preview">
    <template v-if="!editMode">
      <h3>{{ title }}</h3>
      <p>{{ subTitle }}</p>
    </template>

    <div
      class="questions-type-preview__example"
      :class="{
        'questions-type-preview__example--builder': builderMode,
        'questions-type-preview__example--edit': editMode,
      }"
    >
      <template v-if="!editMode">
        <span
          v-if="builderMode"
          class="questions-type-preview__example-title"
          :class="{
            'questions-type-preview__example-title--builder': builderMode,
          }"
          v-html="langObj['s4-customQuestions'].modalTitle1"
        >
        </span>
        <span
          v-else
          class="questions-type-preview__example-title"
          v-html="langObj['s4-customQuestions'].modalTitle2"
        ></span>
      </template>
      <slot></slot>
    </div>
    <p class="questions-type-preview__description">
      <small>{{ description }}</small>
    </p>
  </div>
</template>

<script>
export default {
  name: "QuestionsTypePreview",
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    description: {
      type: String,
    },
    builderMode: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    langObj: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.questions-type-preview {
  h3 {
    margin-bottom: 0;
  }
  &__example {
    background: #f7f7f7;
    padding: 57px 26px 26px;
    position: relative;
    margin: 20px 0 12px;
    &--builder {
      background: #f3f8ff;
    }
    &--edit {
      padding: 20px 26px 26px;
      margin-top: 0;
    }
  }
  &__example-title {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    background: #ebebeb;
    font-size: 13px;
    font-weight: 500;
    color: var(--hsgreyDark);
    &--builder {
      background: #e1efff;
    }
  }
  &__description {
    line-height: 16px;
  }
}
</style>
